import { Container, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { GetAllSettings, GetSelectedBanner, ISettingsUrl } from '../../../../services';
import FloatingImageWhite from '../../../FloatingImageWhite';
import jwtDecode from 'jwt-decode';
import { loginWithEmail } from '../../../../services/user';
import { defaultEmail, defaultPass, CiscoUrl } from '../../../../constants/api';
import conectApp from '../../../../coovaChilli';

interface ILandingPage {
    isMobile?: boolean;
    banners: any[];
}

const SuccessPage = (props: any, { isMobile, banners }: ILandingPage) => {

    const navigate = useNavigate();
    const [settings, setSettings] = useState<ISettingsUrl[]>([]);
    const url_landing = settings.length > 0 ? settings[0].url_landing : '';

    const query = new URLSearchParams(window.location.search);
    const token = query.get('token') as string;
    let decoded: any = null;
    if (token) {
        decoded = jwtDecode(token) as any;
    }

    useEffect(() => {
        const fetchSettings = async () => {
            const settingsData = await GetAllSettings();
            setSettings(settingsData);
        };
        fetchSettings();
        if (decoded)
            loginWithEmail({ email: decoded.email, password: "", method: decoded.method });
    }, []);

    const [defaultImage, setDefaultImage] = useState<string>('');
    const [termscond, setTermscond] = useState<string>('');

    useEffect(() => {
        const getAll = async () => {
            const settings: any[] = await GetAllSettings();
            if (settings && settings.length > 0) {
                if (settings[0].file_encoded) {
                    setDefaultImage(settings[0].file_encoded);
                }
                if (settings[0].termscond) {
                    setTermscond(settings[0].termscond);
                }
            }
        };
        getAll();
    }, []);

    const [buttonClicked, setButtonClicked] = useState<boolean>(false);

    // Boton de aceptar por cisco
    const handleConfirmationButtonClickCisco = async () => {
        setButtonClicked(true);

        const form = document.getElementById('login-form') as HTMLFormElement | null;
        if (form) {
            try {
                const formData = new FormData(form);

                const response = await fetch(CiscoUrl, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const redirectTimeout = parseInt(process.env.REACT_APP_REDIRECT_TIMEOUT_SUCCESS || '2000', 10);

                    const checkBannersAndRedirect = async () => {
                        try {
                            let res = await GetSelectedBanner();

                            // Verificar si res es un objeto y contiene la información del banner
                            if (res && typeof res === 'object' && Object.keys(res).length > 0) {
                                const activeBanner = res.landing_page && res.redirect_url ? res : null;

                                if (activeBanner) {
                                    const absoluteURL = activeBanner.redirect_url.startsWith('http') ? activeBanner.redirect_url : `https://${activeBanner.redirect_url}`;
                                    // Si hay banner activo con landing_page:true y redirect_url definida
                                    setTimeout(() => {
                                        window.location.href = absoluteURL;
                                    }, redirectTimeout);
                                } else {
                                    // Si no hay banner activo, pero hay una url_landing definida
                                    redirectToLandingPage();
                                }
                            } else {
                                // Si no hay banner activo ni url_landing definida
                                redirectToLandingPage();
                            }
                        } catch (error) {
                            console.error("Error al obtener los banners:", error);
                        }
                    };

                    checkBannersAndRedirect();

                } else {
                    console.error('Error en la petición POST:', response.statusText);

                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 3000);
                }
            } catch (error) {
                console.error('Error al manejar la petición POST:', error);

                setTimeout(() => {
                    window.location.href = '/login';
                }, 3000);
            }
        }
    };


    // Boton de aceptar por coovachilli
    const handleConfirmationButtonClickCoova = async () => {
        setButtonClicked(true);

        let email = locationEmail || defaultEmail;
        // const password = locationPassword || defaultPass;
        const password = defaultPass;

        if (socialMediaEmail) {
            email = socialMediaEmail;
        }

        if (email) {
            await conectApp(email, defaultPass);

            const redirectTimeout = parseInt(process.env.REACT_APP_REDIRECT_TIMEOUT_SUCCESS || '2000', 10);

            const checkBannersAndRedirect = async () => {
                try {
                    let res = await GetSelectedBanner();

                    // Verificar si res es un objeto y contiene la información del banner
                    if (res && typeof res === 'object' && Object.keys(res).length > 0) {
                        const activeBanner = res.landing_page && res.redirect_url ? res : null;

                        if (activeBanner) {
                            const absoluteURL = activeBanner.redirect_url.startsWith('http') ? activeBanner.redirect_url : `https://${activeBanner.redirect_url}`;
                            // Si hay banner activo con landing_page:true y redirect_url definida
                            setTimeout(() => {
                                window.location.href = absoluteURL;
                            }, redirectTimeout);
                        } else {
                            // Si no hay banner activo, pero hay una url_landing definida
                            redirectToLandingPage();
                        }
                    } else {
                        // Si no hay banner activo ni url_landing definida
                        redirectToLandingPage();
                    }
                } catch (error) {
                    console.error("Error al obtener los banners:", error);
                }
            };

            checkBannersAndRedirect();
        }
    };

    // Tanto para coovachilli como cisco
    const redirectToLandingPage = () => {
        const redirectTimeout = parseInt(process.env.REACT_APP_REDIRECT_TIMEOUT_SUCCESS || '2000', 10);

        if (url_landing && url_landing !== '/success') {
            setTimeout(() => {
                window.location.href = url_landing;
            }, redirectTimeout);
        } else {
            setTimeout(() => {
                window.location.href = '/landing';
            }, 2000);
        }
    };


    const location = useLocation();
    const { state } = location || {};
    const { email: locationEmail, password: locationPassword } = state || {};

    let socialMediaEmail = '';

    if (decoded) {
        socialMediaEmail = decoded.email || '';
    }

    const [result, setResult] = useState<{ email: string; password: string } | null | undefined>(null);

    const getCiscoConfigAsync = async () => {
        try {
            let email = locationEmail || defaultEmail;
            // const password = locationPassword || defaultPass;
            const password = defaultPass;

            if (socialMediaEmail) {
                email = socialMediaEmail;
            }

            const result = { email, password };

            setResult(result || null);
        } catch (error) {
            console.error('Error obteniendo configuración de Cisco:', error);
            setResult(null);
        }
    };

    useEffect(() => {
        getCiscoConfigAsync();
    }, [locationEmail, socialMediaEmail]);

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;


    const buttonStyles = {
        padding: '1rem 3rem',
        borderRadius: '2rem',
        color: primaryColor,
        fontSize: '1.2rem',
        fontWeight: '800',
        backgroundColor: 'white',
        border: '2px solid ' + primaryColor,
        cursor: 'pointer',
        marginTop: '20px',
    };

    const buttonStylesTerminos = {
        color: 'white',
        fontSize: '0.8rem',
        fontWeight: '600',
        backgroundColor: 'transparent',
        border: '1px solid ' + primaryColor,
        marginTop: '10px',

    };

    let additionalForm: JSX.Element | null = null;

    const provider = process.env.REACT_APP_PROVIDER;

    const commonStyles = {
        maxHeight: '300px',
        width: '100%',
        maxWidth: '500px',
        overflowY: 'auto',
        margin: '0 auto',
        marginTop: '17px',
        borderRadius: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
    } as React.CSSProperties;

    const mobileStyles = {
        maxHeight: '280px',
        width: '100%',
        maxWidth: '500px',
    } as React.CSSProperties;

    const responsiveMobileStyles = {
        '@media (max-width: 768px)': {
            ...mobileStyles,
        },
        '@media (max-width: 480px)': {
            ...mobileStyles,
            'button': {
                fontSize: '14px',
                padding: '8px 30px',
                marginRight: '10px',
                marginLeft: '10px',
            },
        },
    };

    switch (provider) {
        case 'COOVACHILLI':
            additionalForm = (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            marginTop: '-10px',
                            ...responsiveMobileStyles['@media (max-width: 480px)'],
                            marginBottom: '60px',
                        }}
                    >
                        <button
                            onClick={handleConfirmationButtonClickCoova}
                            style={{
                                ...buttonStyles,
                                ...responsiveMobileStyles['@media (max-width: 480px)'].button,
                            }}
                        >
                            ACEPTAR
                        </button>
                        <button
                            onClick={() => navigate('/login')}
                            style={{
                                ...buttonStyles,
                                ...responsiveMobileStyles['@media (max-width: 480px)'].button,
                            }}
                        >
                            CANCELAR
                        </button >
                    </div >
                </>
            );
            break;
        case 'CISCO':
            additionalForm = (
                <>
                    <form
                        action={CiscoUrl}
                        method="post"
                        id="login-form"
                    >
                        <div className="input-field">
                            <label htmlFor="email" hidden>Email</label>
                            <input type="hidden" name="username" required={true} value={result?.email || ''} />
                            <label htmlFor="password" hidden>Password</label>
                            <input type="hidden" name="password" required={true} value={result?.password || ''} />
                            <input type="hidden" name="buttonClicked" size={16} maxLength={15} value="4" />
                        </div>
                    </form>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            marginTop: '-10px',
                            ...responsiveMobileStyles['@media (max-width: 480px)'],
                            marginBottom: '60px',
                        }}
                    >
                        <button
                            onClick={handleConfirmationButtonClickCisco}
                            style={{
                                ...buttonStyles,
                                ...responsiveMobileStyles['@media (max-width: 480px)'].button,
                            }}
                        >
                            ACEPTAR
                        </button>
                        <button
                            onClick={() => navigate('/login')}
                            style={{
                                ...buttonStyles,
                                ...responsiveMobileStyles['@media (max-width: 480px)'].button,
                            }}
                        >
                            CANCELAR
                        </button >
                    </div >
                </>
            );
            break;
        default:
            throw new Error(`Proveedor no válido: ${provider}`);
    }

    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            <a style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                <img
                    style={{
                        backgroundColor: 'white',
                        height: `${isMobile ? 'auto' : '100vh'}`,
                        width: "100%",
                        backgroundSize: "contain",
                        objectFit: "contain",
                    }}
                    src={(`data:image/jpeg;base64,${defaultImage}`)}
                    alt="logo"
                />
            </a>
            <Container component="main"
                style={{
                    height: `${props.isMobile ? 'auto' : '100vh'}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: primaryColor
                }}
            >
                <Box>
                    <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
                        <div style={{ ...commonStyles, ...mobileStyles }}>
                            <div
                                style={{
                                    margin: 0,
                                    whiteSpace: 'pre-wrap',
                                    fontFamily: 'inherit',
                                    fontSize: '16px',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    padding: '12px',
                                    marginTop: '-18px',
                                    ...responsiveMobileStyles,
                                }}
                                dangerouslySetInnerHTML={{ __html: termscond }}
                            />
                        </div>
                        <button
                            style={buttonStylesTerminos}
                        >
                            ¿Aceptas los términos y condiciones?
                        </button>
                    </Typography>
                    {additionalForm}
                </Box>
            </Container>
            <FloatingImageWhite />
        </>
    )
}

export default SuccessPage