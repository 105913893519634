import React from 'react';

const FloatingImageWhite = () => {
    return (
        <div style={{ position: 'fixed', margin: '0px', zIndex: 999, right: '-5px', bottom: '-15px' }}>
            <img src={require("./image/wizzieLogoBlanco.png")} alt="Logo" style={{ padding: '0px', height: '75px' }} />
        </div>
    );
}

export default FloatingImageWhite;
